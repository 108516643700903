import { loadMapKey } from '@/services/map-key/map-key.service'
import { useRequest, useSessionStorageState } from 'ahooks'
import { useEffect } from 'react'

export function useMapKey() {
  const [key, setKey] = useSessionStorageState<string>('mapKey')
  const [sCode, setSCode] = useSessionStorageState<string>('mapSecurityCode')
  const {run} = useRequest(loadMapKey, {
    manual: true,
    onSuccess: (data: string[]) => {
      data[0] && setKey(data[0])
      data[1] && setSCode(data[1])
    },
    onError: (e) => {

    }
  })
  useEffect(() => {
    !key && run()
  }, [])

	return { key, sCode }
}
