import { Component } from 'react'

export interface AMapLoaderProps {
	aKey: string
	version?: string
	plugins?: string[]
	retryTimes?: number
	securityCode?: string
	[key: string]: any
}

export interface AMapLoaderState {
	isLoaded: boolean
	isUILoaded: boolean
}

const buildUrl = (option: { [key: string]: any }) => {
	const params = Object.keys(option)
		.filter(k => option[k])
		.map(k => {
			return `${k}=${option[k]}`
		})
		.join('&')
	const baseUrl = '//webapi.amap.com/maps'

	return baseUrl + '?' + params
}

const defaultRetry = 3

export class AMapLoader extends Component<AMapLoaderProps, AMapLoaderState> {
	retryTimes = defaultRetry
	constructor(props: AMapLoaderProps) {
		super(props)
		this.state = {
			isLoaded: false,
			isUILoaded: false
		}
	}

	componentDidMount() {
		if (!window.AMap) {
			const { aKey, securityCode, version, plugins = ['AMap.Geocoder', 'AMap.MarkerClusterer'], retryTimes = 3 } = this.props

			if (securityCode) {
				(window as any)._AMapSecurityConfig = {
					securityJsCode: securityCode
				}
			}

			const option: { [key: string]: any } = {
				key: aKey,
				v: version,
				plugin: plugins.join(',')
			}
			this.retryTimes = retryTimes
			this.loadScript(option)
		} else {
			this.setState({
				isLoaded: true
			})
			this.onLoaded()
		}
	}

	onLoaded() {
		const { isLoadUI } = this.props
		if (this.props.onLoaded) {
			this.props.onLoaded()
		}

		if (isLoadUI) {
			if (!window.AMapUI) {
				this.loadUIScript()
			} else {
				this.setState({
					isUILoaded: true
				})
				this.onUILoaded()
			}
		}
	}

	onUILoaded() {
		if (this.props.onUILoaded) {
			this.props.onUILoaded()
		}
	}

	loadScript(option: { [key: string]: any }) {
		const script = document.createElement('script')
		script.type = 'text/javascript'
		script.async = true
		script.src = buildUrl(option)
		script.onerror = () => {
			setTimeout(() => {
				this.retryTimes--
				if (this.retryTimes >= 0) {
					this.loadScript(option)
				} else {
					console.warn('Reached the maximum retry times.')
				}
			}, 10 * 1000)
		}
		script.onload = () => {
			this.setState({
				isLoaded: true
			})
			this.onLoaded()
		}

		document.body.appendChild(script)
	}

	loadUIScript() {
		const script = document.createElement('script')
		script.type = 'text/javascript'
		script.async = true
		script.src = '//webapi.amap.com/ui/1.1/main.js?v=1.1.1'
		script.onerror = () => {
			setTimeout(() => {
				this.retryTimes--
				if (this.retryTimes >= 0) {
					this.loadUIScript()
				} else {
					console.warn('Reached the maximum retry times.')
				}
			}, 10 * 1000)
		}
		script.onload = () => {
			this.setState({
				isUILoaded: true
			})
			this.onUILoaded()
		}

		document.body.appendChild(script)
	}

	render() {
		return this.props.isLoadUI
			? this.state.isUILoaded
				? this.props.children
				: ''
			: this.state.isLoaded
			? this.props.children
			: ''
	}
}
