import { FullCenter, IconFont, Logo } from '@/components'
import Restricted from '@/components/Restricted'
import HeaderMenu from '@/layouts/HomeLayout/HeaderMenu/HeaderMenu'
import NotificationBox from '@/layouts/HomeLayout/NotificationBox'
import { getCorp } from '@/store/corporation/corporation.slice'
import { updateIsOpen } from '@/store/notification/notification.slice'
import { fetchUser, selectUser } from '@/store/user/user.slice'
import { memo, Suspense, useEffect, useState } from 'react'
import { Badge, Layout, Skeleton, Space, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { HeaderLeft, HomeHeader, LayoutContent } from './style'
import UserAction from './UserAction/UserAction'
import DataPush from '@/layouts/HomeLayout/DataPush'
import { getVehiclesByIdsThunk } from '@/store/vehicle/vehicle.slice'
import { getGroupsThunk } from '@/store/vehicle/vehicle.thunks'
import { logoutAction, RootState } from '@/store'
import { storageAllIcons } from '@/hooks/useVehicleIcon'
import NBMap from '@/libs/nb-map'
import { useMapKey } from '@/hooks/useMapKey'

export default memo(function HomeLayout() {
	const dispatch = useDispatch()
	const { userInfo } = useSelector(selectUser)
	const groups = useSelector((state: RootState) => state.vehicleState.groups)
	const selectedVehicle = useSelector((state: RootState) => state.vehicleState.selectedVehicle)
	const [ids, setIds] = useState<string[]>([])
	const notifications = useSelector((state: RootState) => state.notification.notifications)
	const { key, sCode } = useMapKey()

	useEffect(() => {
		storageAllIcons()
		dispatch(getCorp())
		const userPromise = dispatch(fetchUser()) as any
		userPromise.then(() => {
			dispatch(getGroupsThunk())
		})

		return () => {
			dispatch(logoutAction())
		}
	}, [])

	useEffect(() => {
		if (groups.length === 0) {
			return
		}
		const vIds = groups.reduce((ids: string[], g) => {
			return ids.concat(g.vIds)
		}, [])
		dispatch(getVehiclesByIdsThunk(vIds))
	}, [groups])

	useEffect(() => {
		if (selectedVehicle) {
			const tIds = selectedVehicle.terminals.reduce((prev: string[], terminals) => {
				return prev.concat(terminals.tid)
			}, [])
			setIds(tIds)
		} else {
			setIds(['null'])
		}
	}, [selectedVehicle])

	if (!userInfo) {
		return (
			<FullCenter>
				<Spin size='large' tip='Loading...' />
			</FullCenter>
		)
	}

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<HomeHeader>
				<HeaderLeft>
					<Logo />

					<HeaderMenu />
				</HeaderLeft>

				<Space align='center' size={16}>
					<Restricted permission='canQueryAlert'>
						<div style={{ height: 46 }}>
							<Badge style={{ boxShadow: 'none' }} count={notifications.length} showZero={false} overflowCount={99}>
								<IconFont
									type='icon-alerts'
									onClick={() => dispatch(updateIsOpen(true))}
									style={{ fontSize: 22, cursor: 'pointer', color: '#fff' }}
								/>
							</Badge>
						</div>
					</Restricted>

					<UserAction />
				</Space>
			</HomeHeader>

			<LayoutContent>
				<Suspense fallback={<Skeleton active />}>
					<DataPush tIds={ids} />
					<Outlet />
					{!!key && (
						<NBMap.Loader aKey={key} securityCode={sCode} retryTimes={3} version='1.4.15'>
							<NotificationBox />
						</NBMap.Loader>
					)}
				</Suspense>
			</LayoutContent>
		</Layout>
	)
})
